<template>
  <div
    class="gamelist-row-body"
  >
    <slot></slot>
  </div>
</template>

<script>
import { getApp } from '@/assets/js/mixins/common/GetApp'

export default {
  name: 'gamelist-row-body'
}
</script>
